import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { INLINES } from "@contentful/rich-text-types";
import OkrExampleCta from "../components/OkrExampleCta";

export const query = graphql`
	query ($slug: String!) {
		contentfulBlogPost(slug: { eq: $slug }) {
			id
			title
			featuredImage {
				gatsbyImageData
				title
				description
				resize(width: 1200) {
					src
				}
				url
			}
			author {
				profileImage {
					gatsbyImageData
				}
				fullName
				firstName
				id
				slug
			}
			slug
			excerpt
			faq {
				answer {
					answer
				}
				question
			}
			updatedAt(formatString: "MMMM Do, YYYY")
			updatedDate: updatedAt
			publishedDate
			body {
				raw
				references {
					... on ContentfulAsset {
						contentful_id
						__typename
						gatsbyImageData(placeholder: BLURRED)
						title
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
				title
			}
		}
	}
`;

const Post = (props) => {
	const post = props.data.contentfulBlogPost;
	const description = post.excerpt
		? post.excerpt
		: props.data.site.siteMetadata.description;
	const site = props.data.site;
	// const isBrowser = () => typeof window !== "undefined";
	// const url = isBrowser() ? window.location.href : "";

	const options = {
		renderNode: {
			"embedded-asset-block": (node) => {
				return (
					<>
						<GatsbyImage
							className="my-4"
							image={node.data.target.gatsbyImageData}
							alt={node.data.target.title}
						/>
					</>
				);
			},
			[INLINES.HYPERLINK]: (node) => {
				return (
					<a
						href={node.data.uri}
						target={`${
							node.data.uri.startsWith(site.siteMetadata.siteUrl)
								? "_self"
								: "_blank"
						}`}
						rel={`${
							node.data.uri.startsWith(site.siteMetadata.siteUrl)
								? ""
								: "noopener noreferrer"
						}`}>
						{node.content[0].value}
					</a>
				);
			},
		},
	};

	const questions = post.faq?.map((edge) => {
		return {
			"@type": "Question",
			name: `${edge.question}`,
			acceptedAnswer: {
				"@type": "Answer",
				text: `${edge.answer.answer}`,
			},
		};
	});

	const faq = post.faq
		? {
				"@context": "https://schema.org",
				"@type": "FAQPage",
				mainEntity: [questions],
		  }
		: null;

	const schema = [
		{
			"@context": "https://schema.org",
			"@type": "BlogPosting",
			mainEntityOfPage: {
				"@type": "WebPage",
				"@id": `okr-basics/${post.slug}`,
			},
			headline: `${post.title}`,
			description: `${post.excerpt}`,
			image: `${post.featuredImage.url}`,
			author: {
				"@type": "Person",
				name: `${post.author.fullName}`,
				url: `${site.siteMetadata.siteUrl}/author/${post.author.slug}`,
			},
			publisher: {
				"@type": "Person",
				name: `${post.author.fullName}`,
				url: `${site.siteMetadata.siteUrl}/author/${post.author.slug}`,
			},
			datePublished: `${post.publishedDate}`,
			dateModified: `${post.updatedDate}`,
		},
		{
			"@context": "https://schema.org",
			"@type": "BreadcrumbList",
			itemListElement: [
				{
					"@type": "ListItem",
					position: 1,
					item: {
						"@id": `${site.siteMetadata.siteUrl}/${post.category?.slug}`,
						name: `${post.category?.title}`,
					},
				},
				{
					"@type": "ListItem",
					position: 2,
					item: {
						"@id": `${site.siteMetadata.siteUrl}/${post.category?.slug}/${post.slug}`,
						name: `${post.title}`,
					},
				},
			],
		},
	];

	return (
		<Layout>
			<Head
				title={`${post.title} | ${props.data.site.siteMetadata.title}`}
				description={description}
				image={post.featuredImage.url}
				url={`${props.data.site.siteMetadata.siteUrl}/${post.slug}`}
				schemaMarkup={schema}
				faq={faq}
			/>

			<div className="max-w-4xl mx-auto mt-4 md:mt-8">
				<article>
					<header className="flex flex-col">
						<h1 className="my-2 text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl md:text-6xl">
							{post.title}
						</h1>
						<p className="text-xl leading-normal">{post.excerpt}</p>
						<div className="flex flex-row my-4">
							<div className="">
								<GatsbyImage
									className="w-12 rounded-full"
									image={post.author.profileImage.gatsbyImageData}
									alt={post.author.fullName}
								/>
							</div>
							<div className="ml-2">
								<p className="text-lg">{post.author.fullName}</p>
								<p className="text-sm font-light">
									Last updated on {post.updatedAt}
								</p>
							</div>
						</div>
					</header>
					<GatsbyImage
						className="my-4"
						image={post.featuredImage.gatsbyImageData}
						alt={post.featuredImage.title}
					/>
					<div
						className="
        prose-p:text-lg prose-p:my-6 prose-blockquote:my-4 prose-blockquote:bg-indigo-500 prose-blockquote:text-white prose-blockquote:mb-4 prose-blockquote:rounded prose-blockquote:px-4 prose-blockquote:py-1
        prose-h2:mt-8 prose-h2:mb-4 prose-h2:text-4xl prose-h2:font-bold prose-h2:text-slate-800
        prose-h3:mb-4 prose-h3:mt-6 prose-h3:text-3xl prose-h3:font-bold prose-h3:text-slate-900
        prose-li:list-disc prose-li:ml-3 prose-li:mt-1.5 prose-li:text-thin prose-li:text-slate-800
        prose-a:text-indigo-600 prose-a:underline">
						{renderRichText(post.body, options)}
					</div>
				</article>
				<OkrExampleCta />
			</div>
		</Layout>
	);
};

export default Post;
