import React from "react";
import { Link } from "gatsby";

const OkrExampleCta = () => {
	return (
		<div className="rounded-md my-8 p-10 bg-slate-100 sm:text-center">
			<h2 className="text-3xl sm:text-4xl font-bold tracking-tight text-gray-900 mb-2">
				Browse +100 OKR examples
			</h2>
			<p className="leading-7">
				They're hard to come up with but we've got you! Browse +100 examples of
				OKRs from various companies and find inspiration for your next objective
				or key result.
			</p>
			<form
				className="mt-8 sm:mt-10 lg:mt-8 mx-auto flex flex-col sm:flex-row w-full justify-center"
				name="contact">
				<Link
					to={`/okr-examples/`}
					className="bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 ring-1 ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-indigo-50 text-white font-semibold h-14 px-6 mt-4 sm:mt-0 rounded-lg w-full flex items-center justify-center sm:w-auto">
					Browse +100 examples
				</Link>
			</form>
		</div>
	);
};

export default OkrExampleCta;
